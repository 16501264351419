<template lang="pug">
  div
    .sidebar-layout__toolbar
      button.btn.btn--brand.ml-2(
        v-permission-hide="permissions.create_ration"
        @click="modals.showCreateRationModal = true")
        font-awesome-icon.mr-1(
          size="sm"
          icon="plus")
        span {{ isMobile ? 'actions.create_mobile' : 'pages.rations.create' | translate }}
    v-dialog(
      max-width="350"
      v-model="modals.showCreateRationModal")
      create-ration-modal(
        @close="modals.showCreateRationModal = false")

    //- FOR DESKTOP AND TABLETS
    template(v-if="!isMobile")
      rations-table

    //- FOR MOBILE ONLY
    template(v-if="isMobile")
      rations-table-mobile

</template>

<script>
import adaptationMixin from '@/mixins/adaptation.mixin'

import CreateRationModal from './modals/CreateRationModal'
import permissions from '@/util/permissions'

const RationsTable = () => import('./RationsTable')
const RationsTableMobile = () => import('./RationsTableMobile')

export default {
  name: 'Ration',

  mixins: [adaptationMixin],

  components: {
    CreateRationModal,
    RationsTable,
    RationsTableMobile
  },

  data: () => ({
    modals: {
      showCreateRationModal: false
    },
    permissions: permissions
  })
}
</script>

<style lang="scss" scoped>
</style>
